@page {
    size: A4;
    margin: 0;
}

.page {
    flex-shrink: 0;
    display: inline-block;
    position: relative;
    height: 297mm;
    width: 210mm;
    margin: 2em auto;
    padding: 1.5cm;
    box-shadow: 0px 3px 3px -2px rgba(0,0,0,0.2), 
        0px 3px 4px 0px rgba(0,0,0,0.14),
        0px 1px 8px 0px rgba(0,0,0,0.12); /* copied from MUI boxShadow 3 */
    background: white;
}

.commentsHeader {
    max-width: 210mm;
    display: flex;
    flex-direction: column;
    row-gap: 8px;
}

.intro {
    max-width: 210mm;
    margin-bottom: 2rem;
}

.row {
    display: flex;
    flex-direction: row;
    column-gap: 64px;
}

.column {
    flex-shrink: 0;
}

.resume {
    height: 100%;
    display: flex;
    flex-direction: column;
    font-family: "Merriweather", serif;
    font-size: 10pt;
    line-height: 156%;
}

.commentsSidebar {
    overflow: hidden;
    margin-top: calc(1.5cm);
    padding-top: 2rem;
    align-self: flex-start;
    position: sticky;
    top: 0;

    .comment:not(.commentSelected) {
        cursor: pointer;
    }
}

.commentsColumn {
    display: flex;
    flex-direction: column;
    row-gap: 8px;
}

.field {
    display: inline-block;
    white-space: pre-wrap;
    border-radius: 4px;
}

.highlight {
    background-color: #f8f1ce;
}

.highlight:not(.highlightSelected) {
    cursor: pointer;
}

.highlightSelected,
.highlightSelected .field {
    background-color: #ffe55f;
}

.comment {
    padding: 16px;
    background-color: #eff5fd;
}

.commentsSidebar .comment {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.commentSelected {
    background-color: #8abdff;
    border-radius: 4px;
}

.commentsSidebar .commentSelected {
    margin: 24px 0;
    padding: 16px;
    overflow: visible;
    white-space: pre-line;
    text-overflow: clip;
}

.sectionContacts {
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 16px;
}

.inline {
    display: inline;
}

.title {
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 10px;
    font-weight: bold;
    font-size: 13pt;
}

.contacts {
    align-self: center;
}

.sectionTitle {
    display: flex;
    font-weight: bold;
    font-size: 13pt;
}

.jobCompanyProfile {
    display: flex;
    flex-direction: row;
    column-gap: 2rem;
}

.jobCompany {
    display: flex;
    font-weight: bold;
}

.jobProfile {
    display: flex;
    font-style: italic;
}

.jobPositionDates {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.jobPosition {
    display: flex;
}

.jobDates {
    display: flex;
}

.jobDescription {}

.schoolNameDate {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.schoolName {
    font-weight: bold;
}

.schoolDegreeDate {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.schoolDegree {
    display: flex;
}

.schoolDate {
    display: flex;
}

.schoolDescription {}

.certificationNameDate {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.certificationName {
    font-weight: bold;
}

.certificationDate {
    display: flex;
}

.certificationDescription {}

.projectOrganization {
    font-weight: bold;
}

.projectTitleDate {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.projectTitle {
    font-weight: bold;
}

.projectDate {
    display: flex;
}

.projectDescription {}

.gap64 {
    display: flex;
    flex-basis: 64px;
    min-height: 32px;
    max-height: 64px;
    flex-shrink: 8;
}

.gap32 {
    display: flex;
    flex-basis: 32px;
    min-height: 16px;
    max-height: 32px;
    flex-shrink: 4;
}

.gap16 {
    display: flex;
    flex-basis: 16px;
    min-height: 8px;
    max-height: 16px;
    flex-shrink: 2;
}

.gap8 {
    display: flex;
    flex-basis: 8px;
    min-height: 4px;
    max-height: 8px;
    flex-shrink: 1;
}