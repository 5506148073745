* {
    box-sizing: border-box;
}

body {
    margin: 0;
    padding: 0;
    font-family: "Source Sans 3", sans-serif;
    line-height: 1.8;
}
